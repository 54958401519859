"use client"

import { FC, JSX } from "react"

interface WaitForProps {
  isReady: boolean
  children: React.ReactNode
  as: JSX.Element
}

export const WaitFor: FC<WaitForProps> = ({
  isReady,
  children,
  as: SkeletonComponent,
}) => {
  if (!isReady) {
    return SkeletonComponent
  }

  return children
}

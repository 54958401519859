import { getUrl } from "@cyna/common/routes"
import { Link } from "@cyna/components/base/Link"
import { Typo } from "@cyna/components/base/Typo"
import { WaitFor } from "@cyna/components/base/WaitFor"
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
} from "@cyna/components/ui/breadcrumb"
import { Separator } from "@cyna/components/ui/separator"
import { SidebarTrigger } from "@cyna/components/ui/sidebar"
import { Skeleton } from "@cyna/components/ui/skeleton"
import { TooltipProvider } from "@cyna/components/ui/tooltip"
import { cn } from "@cyna/components/utils"
import { Fragment, ReactNode } from "react"

interface Breadcrumb {
  link?: ReturnType<typeof getUrl>
  text: ReactNode
  isReady?: boolean
}

const IsReadyBreadcrumb = ({
  children,
  isReady,
}: {
  children: ReactNode
  isReady?: boolean
}) =>
  typeof isReady === "boolean" ? (
    <WaitFor
      as={<Skeleton className="bg-foreground/30 h-4 w-2/5" />}
      isReady={isReady}
    >
      {children}
    </WaitFor>
  ) : (
    children
  )

export const Page = ({
  children,
  className,
  breadcrumbs,
  cta,
  hasNarrowContent,
  title,
}: {
  breadcrumbs: [Breadcrumb, ...Breadcrumb[]]
  title: string
  children: ReactNode
  className?: string
  cta?: ReactNode
  hasNarrowContent?: boolean
}) => (
  <TooltipProvider>
    <div className="w-full">
      <div className="bg-background sticky top-0 z-10 mb-2 shadow-sm">
        <div className="flex items-center gap-4 p-4">
          <SidebarTrigger />
          <Breadcrumb>
            <BreadcrumbList>
              {breadcrumbs.map((breadcrumb, i) => (
                <Fragment key={i.toString()}>
                  <BreadcrumbItem className="text-base">
                    <IsReadyBreadcrumb isReady={breadcrumb.isReady}>
                      {breadcrumb.link ? (
                        <BreadcrumbLink asChild>
                          <Link styless href={breadcrumb.link}>
                            {breadcrumb.text}
                          </Link>
                        </BreadcrumbLink>
                      ) : (
                        breadcrumb.text
                      )}
                    </IsReadyBreadcrumb>
                  </BreadcrumbItem>
                  <BreadcrumbSeparator className="first:hidden last:hidden" />
                </Fragment>
              ))}
            </BreadcrumbList>
          </Breadcrumb>
        </div>
        <Separator />
      </div>
      <div className="mx-auto max-w-7xl p-6 sm:min-w-96">
        <div className="flex justify-between">
          <Typo as="h1" className="mb-4">
            <h1>{title}</h1>
          </Typo>
          {cta}
        </div>
        <div
          className={cn(
            "mx-auto",
            hasNarrowContent && "mx-0 flex max-w-xl flex-col gap-4",
            className,
          )}
        >
          {children}
        </div>
      </div>
    </div>
  </TooltipProvider>
)
